export const verificationTokenKey = "__RequestVerificationToken";
const verificationFormToken = "__RV_FormToken";

export class RequestAntiForgeryHelper {
    static getAntiForgeryToken() {
        return ep.common.getCookie(verificationFormToken) || "";
    }

    static getAntiForgeryField() {
        return $("<input>").attr({
            type: "hidden",
            name: verificationTokenKey,
            value: this.getAntiForgeryToken()
        });
    }

    static setAntiForgeryToken(requestOptions) {
        if (requestOptions.type.toLowerCase() == "post") {
            requestOptions.headers = requestOptions.headers || {};
            requestOptions.headers[verificationTokenKey] = RequestAntiForgeryHelper.getAntiForgeryToken();
        }
    }
}