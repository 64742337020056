
(function ($, ep) {

    ep.LoginExecuter = function (options) {

        this.options = options;
        this.loginOption = options.loginOptions;
        this.recoverPasswordOption = options.recoverPasswordOption;
        this.changePasswordOptions = options.changePasswordOptions;
        this.showPasswordOptions = options.showPasswordOptions;
    };

    $.extend(ep.LoginExecuter.prototype, {
        init: function () {
            var self = this;

            self.loginFormData = null;
            self.recoveryFormData = null;
            self.changePasswordFormData = null;

            if (!!this.recoverPasswordOption) {
                $(this.recoverPasswordOption.btnSubmitSelector).click($.proxy(this.submitRecoverForm, this));
                $(this.recoverPasswordOption.formSelector).submit(function (e) {
                    e.preventDefault();
                });

                $(this.recoverPasswordOption.formSelector).keypress(function (e) {
                    if (e.which == 13/*enter*/) {
                        self.submitRecoverForm();
                    }
                });
            }

            if (!!this.loginOption) {
                $(this.loginOption.btnSubmitSelector).click($.proxy(this.submitLoginForm, this));
                $(this.loginOption.formSelector).submit(function (e) {
                    e.preventDefault();
                });

                $(this.loginOption.formSelector).keypress(function (e) {
                    if (e.which == 13/*enter*/) {
                        self.submitLoginForm();
                    }
                });
            }
            if (!!this.changePasswordOptions) {
                $(this.changePasswordOptions.btnSubmitSelector).click($.proxy(this.submitChangePasswordForm, this));
                $(this.changePasswordOptions.formSelector).submit(function(e) {
                    e.preventDefault();
                });

                $(this.changePasswordOptions.formSelector).keypress(function(e) {
                    if (e.which == 13 /*enter*/) {
                        self.submitChangePasswordForm();
                    }
                });
            }

            if (!!this.showPasswordOptions) {
                $(this.showPasswordOptions.passwordFieldSelector).passwordObserver();
            }
        },

        submitRecoverForm: function () {
            var self = this;

            if (!this.validateEmptyFieldsForm(this.recoverPasswordOption.formSelector))
                return;

            this.recoveryFormData = this.convertFormToJSON(this.recoverPasswordOption.formSelector);
            this.submitForm(self.recoverPasswordOption, this.recoveryFormData, function (res) {
                if (res.isSuccessResult) {
                    $(self.options.errorMessageContentSelector).hide().find(self.options.messageElemSelector).empty();
                    $(self.recoverPasswordOption.formSelector).hide();
                    $(self.recoverPasswordOption.infoMessageSelector).show().find(self.options.messageElemSelector).html(res.resultMessage);
                } else {
                    $(self.options.successMessageContentSelector).hide().find(self.options.messageElemSelector).empty();
                    $(self.options.errorMessageContentSelector).show().find(self.options.messageElemSelector).html(res.resultMessage);
                }
            });
        },

        submitLoginForm: function () {
            var self = this;

            if (!this.validateEmptyFieldsForm(this.loginOption.formSelector))
                return;

            this.loginFormData = this.convertFormToJSON(this.loginOption.formSelector);
            this.submitForm(this.loginOption, this.loginFormData, function (res) {
                if (res.isSuccessResult) {
                    self.successfulLogin(res);
                } else {
                    if (res.ErrorCode === 3) 
                        self.switchToChangePassword(res);
                    else
                        self.showError(res.ErrorMessage);

                    $(self.loginOption.btnSubmitSelector).removeClass('loading');
                }
                    
            }, true);
        },
        
        switchToChangePassword: function (loginResult) {
            this.passwordPolicyData = loginResult.PasswordPolicyData;

            this.options.switchToChangePassword();

            if (this.passwordPolicyData.Expression) {
                $('#password-change-rule span').html(this.passwordPolicyData.ErrorMessagePasswordStrength);
                $('#password-change-rule').show();
            }

            var self = this;
            $('#newPassword-input').on("input", function () {
                if (self.allowRulesValidationOnChange)
                    self.validateExpiredPasswordRules();
            });
        },

        validateExpiredPasswordRules: function () {
            this.allowRulesValidationOnChange = true;

            var isStrength = true;
            var isNotProhibitedWord = true;

            if (this.passwordPolicyData.Expression) {
                isStrength = new RegExp(this.passwordPolicyData.Expression).test($("#newPassword-input").val());
            }

            if (this.passwordPolicyData.ProhibitedWords) {
                isNotProhibitedWord = this.passwordPolicyData.ProhibitedWords.indexOf($("#newPassword-input").val()) == -1;
            }

            if (isStrength && isNotProhibitedWord) {
                $('#validationMessage1').hide();
                $('#validationMessage2').hide();

                return true;
            }

            if (!isStrength)
                $('#validationMessage1').show();
            else if (!isNotProhibitedWord)
                $('#validationMessage2').show();

            return false;
        },

        submitChangePasswordForm: function () {
            if (!this.validateEmptyFieldsForm(this.changePasswordOptions.formSelector))
                return;

            if (!this.validateExpiredPasswordRules())
                return;

            var request = $.extend({},this.loginFormData);
            var changePasswordData = this.convertFormToJSON(this.options.changePasswordOptions.formSelector);

            $.extend(request, changePasswordData);
            
            var self = this;

            $(self.options.changePasswordOptions.btnSubmitSelector).addClass('loading');

            ep.common.repostJson(this.options.changePasswordOptions.url, request)
                .done(function (res) {                    

                    if (res.isSuccessResult)
                        self.successfulLogin(res);
                    else {
                        $('#validationMessage1').hide();
                        $('#validationMessage2').hide();

                        $('#validationMessage1 span').html(res.ErrorMessage);
                        $('#validationMessage1').show();

                        $(self.options.changePasswordOptions.btnSubmitSelector).removeClass('loading');
                    }
            });
        },

        submitForm: function (options, data, callback, continueLoading) {
            $(options.btnSubmitSelector).addClass('loading');

            ep.common.repostJson(options.url, data)
                .done(function (res) {
                    callback(res);
                    if (!continueLoading)
                        $(options.btnSubmitSelector).removeClass('loading');
                });
        },

        successfulLogin: function (successfulLoginResult) {
            this.redirectTo(successfulLoginResult.NavigationUrl);
        },

        redirectTo: function (url) {
            window.location = url;
        },

        convertFormToJSON: function (formSelector) {
            var array = $(formSelector).serializeArray();

            var json = {};

            $.each(array, function () {
                json[this.name] = this.value || '';
            });

            return json;
        },

        validateEmptyFieldsForm: function (formSelector) {
            var inputs = $(formSelector).find("input").not("[type='hidden']").not("[type='checkbox']");

            var success = true;
            $.each(inputs, function (index, el) {
                if (!$(el).val()) {
                    $(el).focus();
                    success = false;
                    return false;
                }
            });

            return success;
        },

        showError: function (error) {
            $(this.options.successMessageContentSelector).hide().find(this.options.messageElemSelector).empty();
            $(this.options.errorMessageContentSelector).show().find(this.options.messageElemSelector).html(error);
        }
    });

})(jQuery, ep);
