$.fn.passwordObserver = function () {
    let input = this;
    let wrapper = $('<div></div>').addClass("eyed-input");
    let eye = $('<div></div>').addClass("input-eye");

    this.wrap(wrapper)
        .parent()
        .append(eye);

    let hidePassword = function () {
        input.attr("type", "password");
    };
    let showPassword = function () {
        input.attr("type", "text");
    };

    eye.mousedown(() => {
        showPassword();
    }).mouseup(() => {
        hidePassword();
    }).mouseout(() => { //for covering situation when mouseup was triggered outside trigger-element
        hidePassword();
    });
};