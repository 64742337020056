import {isUndefined, isNull, escape, reduce} from "lodash";

export const throttleAjaxAction = (sourceFunc) => {
    return function wrapped() {
        if (wrapped._inProgress) {
            return false;
        }
        wrapped._inProgress = true;

        var sourceFuncRes = sourceFunc.apply(this, arguments);

        if (sourceFuncRes && sourceFuncRes.always) {
            sourceFuncRes.always(function () {
                wrapped._inProgress = false;
            });
        }else{
            wrapped._inProgress = false;
        }

        return sourceFuncRes;
    };
};

export const QTip2 = (element, options, notation, newValue) => {
    const defaults = $.fn.qtip.defaults;
    const tooltip = $(element);
    let settings = options;
    if ("object" === typeof options) {
        settings = Object.assign({}, defaults, options);
    }
    tooltip.qtip(settings, notation, newValue);
};

export const getWindowSize = () => ({height : window.innerHeight, width: window.innerWidth});

export const isPrintableKey = function (keycode) {
    var isPrintable =
        isNumberKey(keycode) ||
        keycode == 32 || keycode == 13 || // spacebar & return key(s) (if you want to allow carriage returns)
        (keycode > 64 && keycode < 91) || // letter keys
        (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
        (keycode > 218 && keycode < 223);   // [\]' (in order)
    return isPrintable;
};

export const isNumberKey = function (keycode) {
    return (keycode >= ep.Keys.zero && keycode <= ep.Keys.nine) ||  // number keys
        (keycode > 95 && keycode < 112); // numpad keys
};
    /* eslint-disable */

// *** Polyfills ***
// IE (math.sign)
Math.sign = Math.sign || function (x) {
    x = +x;
    if (x === 0 || isNaN(x)) {
        return x;
    }
    return x > 0 ? 1 : -1;
};
// IE (matches / closest)
(function(el) {
    el.matches = el.matches || el.mozMatchesSelector || el.msMatchesSelector || el.oMatchesSelector || el.webkitMatchesSelector;
    el.closest = el.closest || function closest(selector) {
        if (!this) return null;
        if (this.matches(selector)) return this;
        if (!this.parentElement) {return null}
        else return this.parentElement.closest(selector)
    };
}(window.Element.prototype));
// *** Ends Polyfills ***

window.registerNS = function registerNS(ns) {
    var nsParts = ns.split(".");
    var root = window;

    for (var i = 0; i < nsParts.length; i++) {
        if (typeof root[nsParts[i]] == "undefined")
            root[nsParts[i]] = new Object();

        root = root[nsParts[i]];
    }
};

registerNS("ep.common");

ep.common.trySetWebHTTPProtocol = function (link) {
    if (!link || !$.trim(link))
    { return null; }

    if (link.search(/^((http|https|ftp|ftps|news|mms|rtmp|rtmpt|e2dk|javascript|file)\:\/\/)|(\\\\)/i) == -1) {
        link = 'http://' + link;
    }
    return link;
};

export let currentCultureSettings;
window.setCulture = function(cultureName) {
    ep.currentCulture = cultureName;
    currentCultureSettings = ep.currentCultureSettings = ep.cultures[ep.currentCulture];

    if (!ep.currentCultureSettings) {
        (function () {
            var currentKey = ep.currentCulture.slice(0, 2).toLowerCase();
            ep.currentCulture = "en-US";
            for (var key in ep.cultures) {
                if (key.slice(0, 2).toLowerCase() == currentKey) {
                    ep.currentCulture = key;
                    currentCultureSettings = ep.currentCultureSettings = ep.cultures[key];
                    return;
                }
            }
            currentCultureSettings = ep.currentCultureSettings = ep.cultures[ep.currentCulture];
        })();
    }
};



window.setupDatepicker = function () {
    var defaults = {};

    $.extend(defaults, $.datepicker.regional[ep.currentCulture]);

    $.extend(defaults, {
        minDate: new Date(1900, 1, 1)
    });

    $.datepicker.setDefaults(defaults);
};

ep.defaultMinDate = new Date(1900, 0, 1, 0, 0, 0);
ep.defaultMaxDate = new Date(2099, 11, 31, 0, 0, 0);

ep.newLinesRegex = /\n/gi;

ep.common.sin = function (angle) {
    return Math.sin(angle * Math.PI / 180.0);
};

//obsolete, better to use  _.escape
ep.common.htmlEncode = function (text) {
    return document.createElement('a').appendChild(document.createTextNode(text)).parentNode.innerHTML; // todo: replace with textarea?
};

ep.common.htmlDecode = function (html) {
    var a = document.createElement('a'); // todo: replace with textarea?
    a.innerHTML = html;
    return a.textContent;
};

ep.common.slideUp = function (element) {
    return $(element).slideUp().promise();
};

export const highlightText = (text, textForHighlight, wrapper) => {
    const pattern = escapeRegExp(textForHighlight);
    const matchRegex = new RegExp(pattern, 'gi');
    return highlightTextByRegex(text, matchRegex, wrapper)
};
ep.common.highlightText = highlightText;

const highlightTextByRegex = (text, matchRegex, wrapper) => {
    if (!matchRegex || isUndefined(text) || isNull(text)) {
        return escape(text); 
    }

    let matches = text.match(matchRegex);
    if (!matches) {
        return escape(text);
    }

    let strings = text.split(matchRegex);

    let result = reduce(strings, function (memo, str, index) {
        if (index == 1 && memo != "") {
            memo = escape(memo);
        }

        let encodedTextForHighlight = escape(matches[index - 1]);
        let wrappedEncodedMatch = wrapper ? wrapper(encodedTextForHighlight) : '<strong>' + encodedTextForHighlight + '</strong>';

        return memo + wrappedEncodedMatch + escape(str);
    });

    return result;
};
ep.common.highlightTextByRegex = highlightTextByRegex;


ep.common.highlightWrapper = function(val) {
    return "<mark>" + val + "</mark>";
}
// remove "c:\fakepath\" for file name in IE & Chrome
ep.common.removeFakePath = function(fileName) {
    return fileName.split('\\').pop();
};

ep.common.getFileExtension = function (fileName) {
        var fileExtension = "";
        if (fileName.indexOf('.') != -1) {
            fileExtension = fileName.match(/([^.]+)$/)[0];
        }
        return fileExtension;
    };

ep.common.getFileName = function(fileName) {
    if (fileName.indexOf('.') != -1) {
        fileName = fileName.substring(0, fileName.lastIndexOf(".")) + ".";
    }

    return fileName;
};

ep.common.putCursorAtEnd = function (input) {
    $(input).focus();

    // If this function exists...
    if (input.setSelectionRange) {
        // ... then use it
        // (Doesn't work in IE)

        // Double the length because Opera is inconsistent about whether a carriage return is one character or two. Sigh.
        var len = $(input).val().length * 2;
        input.setSelectionRange(len, len);
    }
    else {
        // ... otherwise replace the contents with itself
        // (Doesn't work in Google Chrome)
        ep.common.unselect(input);
    }

    // Scroll to the bottom, in case we're in a tall textarea
    // (Necessary for Firefox and Google Chrome)
    input.scrollTop = 999999;
};

ep.common.unselect = function (input) {
    $(input).val($(input).val());
};

ep.common.showHtmlText = function (text) {
    if (text) {
        return ep.common.htmlEncode(text).replace(new RegExp("\\n", "g"), "<br />");
    } else {
        return '';
    }
};

export const adjustPosition = (baseEl, adjustEl, options = {}) => {
    const defaultSettings = {
        adjustOnSideOfItem: false,
        viewport: "[data-ep-viewport]",
        adjustedAbove: "adjusted-above",
        adjustedRight: "adjusted-right",
        borderSize: 1,
        indent: {
            x: 0,
            y: 0
        }
    };
    let settings = {...defaultSettings, ...options};

    let borderSize = settings.withBorder ? settings.borderSize : 0;

    let scroll = {
        top: window.pageYOffset,
        left: window.pageXOffset
    };

    let viewPort = {
        offsetTop: 0,
        offsetLeft: 0,
        height: 0,
        width: 0
    };

    let viewPortContainer = baseEl.closest(settings.viewport);
    if (viewPortContainer) {
        viewPort = viewPortContainer.getBoundingClientRect();
        viewPort.offsetTop = viewPort.top + scroll.top;
        viewPort.offsetLeft = viewPort.left + scroll.left;
    } else {
        viewPort.width = window.innerWidth;
        viewPort.height = window.innerHeight;
    }

    let baseRect = baseEl.getBoundingClientRect();
    baseRect.offsetTop = baseRect.top + scroll.top;
    baseRect.offsetLeft = baseRect.left + scroll.left;

    let targetOffsetPosition = {
        top: baseRect.offsetTop - viewPort.offsetTop,
        left: baseRect.offsetLeft - viewPort.offsetLeft
    };

    let realTargetTop = targetOffsetPosition.top - scroll.top;
    let realTargetLeft = targetOffsetPosition.left - scroll.left;

    let baseElHeight = settings.underBaseElement ? baseRect.height : 0;
    let baseElHeightInverted = settings.underBaseElement ? 0 : baseRect.height;
    let baseElWidth = settings.adjustOnSideOfItem ? baseRect.width : 0;
    let baseElWidthInverted = settings.adjustOnSideOfItem ? 0 : baseRect.width;

    let viewportRelativeTop = settings.viewportTop ? (settings.viewportTop - viewPort.offsetTop) : 0;
    let bottomViaViewport = Math.min(targetOffsetPosition.top - viewportRelativeTop, realTargetTop) + baseElHeightInverted;

    let marginX = settings.indent.x || 0;

    let left = -borderSize + baseElWidth + marginX;
    let style = {
        left: !settings.forceRightAlign ? `${left}px` : "auto",
        right: !settings.forceRightAlign ? "auto" : `${left}px`,
        position: "absolute",
        // workaround to avoid container "jumps" is to set visibility -> hidden for $elementNeedToAdjust as default
        // those "jumps" mostly visible only in ie (chrome is very fast),
        // but in ie firstly visible container with original position and after adjusting with new position, so it looks like "jumps"
        // so if container is hidden by default -> we can calculate its size but it transparent and there is no "jumps"
        visibility: "visible"
    };

    let adjustRect = adjustEl.getBoundingClientRect();

    let xPos = -1 * adjustRect.width + baseElWidthInverted - borderSize - marginX;

    if (!settings.forceRightAlign && (realTargetLeft + adjustRect.width + baseElWidth + marginX + 30 > viewPort.width) && (realTargetLeft >= Math.abs(xPos))) // check if window has enough place to display element Left
    {
        style.left = `${xPos}px`;
        adjustEl.classList.add(settings.adjustedRight);
    } else {
        adjustEl.classList.remove(settings.adjustedRight);
    }

    let marginY = settings.indent.y || 0;
    let yPos = baseElHeight - borderSize + marginY;

    if (((realTargetTop + adjustRect.height + baseElHeight + 30 > viewPort.height) || settings.forceAbove) && (bottomViaViewport > adjustRect.height + borderSize)) // check if window has enough place to display element UP
    {
        style.top = "auto";
        style.bottom = `${yPos}px`;
        adjustEl.classList.add(settings.adjustedAbove);
    } else {
        style.top = `${yPos}px`;
        style.bottom = "auto";
        adjustEl.classList.remove(settings.adjustedAbove);
    }

    for (let prop in style) {
        if( style.hasOwnProperty( prop ) ) {
            adjustEl.style[prop] = style[prop];
        }
    }
};

ep.common.adjustElementPosition = function (baseElement, elementNeedToAdjust, opts) {
    adjustPosition(baseElement.get(0), elementNeedToAdjust.get(0), opts);
};

ep.common.adjustFixedTop = function(element) {
    var $el = $(element);
    $el.css('top', $.epHeader().height());
};

(function () {
    let cachedResult = null;
    let isTracking = false;

    ep.common.getScrollBarSize = function getScrollBarSize() {
        if (cachedResult) {
            return cachedResult;
        }
        var defaultWidth = 100;
        var $outer = $('<div></div>').css({
                position: 'absolute',
                visibility: 'hidden',
                width: defaultWidth,
                overflow: 'scroll'
            }).appendTo('body'),
            widthWithScroll = $('<div></div>').css({width: '100%'}).appendTo($outer).outerWidth();

        $outer.remove();
        cachedResult = defaultWidth - widthWithScroll;
        return cachedResult;
    };

    // reset cached scroll size for browser zoom in/zoom out
    // because for example chrome doesn't change scrollbars size on zoom level changes
    // what is leads to incorrect cached value.
    ep.common.trackScrollBarSizeChange = function() {
        if (isTracking) return;

        $(window).on("resize", () => {
            cachedResult = null;
        });
        isTracking = true;
    };
})();

ep.common.reloadLocation = function () {
    window.location.href = ep.common.getCurrentLocation();
};

ep.common.openNewTab = function (url) {
    window.open(url);
};

ep.common.getCurrentLocation = function() {
    return window.location.href;
};

ep.common.getCurrentPathName = function() {
    return window.location.pathname;
};

ep.common.addURLParameter = function (key, value, url) {
    if (url === undefined)
        url = ep.common.getCurrentLocation();

    var symbolParameter = '?';
    if (url.indexOf('?') !== -1) {
        symbolParameter = '&';
    }

    var kvPair = key + '=' + encodeURIComponent(value);
    return url.replace(/(#|$)/, symbolParameter + kvPair + "$1" );
};

ep.common.updateURLParameter = function (key, value, url) {
    if (url === undefined)
        url = ep.common.getCurrentLocation();

    var oldValue = ep.common.getURLParameter(key, url);

    if (!oldValue) {
        return ep.common.addURLParameter(key, value, url);
    }

    var kvPairOld = key + '=' + oldValue;
    var kvPairNew = key + '=' + encodeURIComponent(value);

    return url.replace(new RegExp("(\\?|&)" + this.escapeRegExp(kvPairOld) + "(&|#|$)"), "$1" + kvPairNew + "$2");
};

ep.common.getURLParameter = function (name, url) {
    if (url === undefined)
        url = ep.common.getCurrentLocation();
    var results = new RegExp('[\\?&#]' + name + '=([^&#]*)', 'i').exec(url);

    return results ? results[1] : null;
};

ep.common.getDecodedURLParameter = function (name, url) {
    var value = ep.common.getURLParameter(name, url);

    return value ? decodeURIComponent(value) : null;
};

ep.common.getFileExtension = function(fileName){
    if(!fileName)
        return "";

    var ext = fileName.split(".").reverse()[0];
    return ext === fileName ? "" : ext;
};

ep.common.sum = function(array, valueAccessor){
    valueAccessor = valueAccessor || function(x) { return x };
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
        sum += valueAccessor(array[i]);
    }

    return sum;
};

ep.common.uniqueKey = function(options){
    var S4 = function ()
    {
        return Math.floor(
            Math.random() * 0x10000 /* 65536 */
        ).toString(16);
    };

    var result = (
        S4() + S4() + "-" +
            S4() + "-" +
            S4() + "-" +
            S4() + "-" +
            S4() + S4() + S4() +
            "-" + (new Date().getTime() * 10000)
        );

    if(options && options.withoutDash){
        result = result.replace(/-/g, "");
    }

    return result;
};

export const escapeRegExp = (regexp) => regexp.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
ep.common.escapeRegExp = escapeRegExp;

ep.common.getCookie = function (name) {
    const matches = document.cookie.match(new RegExp("(?:^|; )" + escapeRegExp(name) + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

ep.common.trim = function (str, charlist) {
    charlist = !charlist ? ' \\s\xA0' : charlist.replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '\$1');
    var re = new RegExp('^[' + charlist + ']+|[' + charlist + ']+$', 'g');
    return str.replace(re, '');
};

ep.common.isNullOrEmpty = function (str) {
    return (str || "").trim().length == 0;
};

ep.common.round = function(number, precision){
    var roundingMultiplier = Math.pow(10, precision);
    var newValueAsNum = isNaN(number) ? 0 : parseFloat(number);
    var roundedValue = Math.round(newValueAsNum * roundingMultiplier) / roundingMultiplier;

    return roundedValue;
};

ep.common.sort = function (array, valSelector) {
    valSelector = valSelector || function (x) { return x; };

    array.sort(function (left, right) {
        var leftVal = valSelector(left);
        var rightVal = valSelector(right);

        return leftVal == rightVal ? 0 : (leftVal < rightVal ? -1 : 1);
    });

    return array;
};

ep.common.getAbbreviation = function (source) {
    //NOTE: should be in sync with LogicSoftware.EasyProjects.Web.Gui.Code.Extensions.StringExtensions.GetAbbreviation
    if (!source)
        return "";

    return _.chain((" " + source).match(/\s[^\s]/g))
            .map(function (x) {
                return x.charAt(1); //exclude space
            })
            .filter(function (x) {
                return x.toUpperCase() !== x.toLowerCase() || /\d/.test(x);
            })
            .take(4)
            .value()
            .join('')
            .toUpperCase();
};

ep.common.extractTextFromClipboard = function (clipBoardEvent) {
    if (window.clipboardData && window.clipboardData.getData) { // IE
       return window.clipboardData.getData('Text');
    } else if (clipBoardEvent.clipboardData && clipBoardEvent.clipboardData.getData) {
        return clipBoardEvent.clipboardData.getData('text/plain');
    }
};

ep.initTooltipSettings = function(){
    if(ep.tooltipData !== undefined)
        return;

    ep.tooltipData = {
        position:{
            my:"top center",
            at:"bottom center",
            viewport:$(window)
        },
        content:null,
        show:{
            solo:true,
            ready: true,
            delay:500
        },
        hide:{
            fixed:true,
            event:"unfocus click mouseleave",
            delay:20
        }
    };
};

ep.isInIframe = function() {
    try {
        return window.parent && window.parent !== window;
    } catch(e) {
        return true;
    }
};

/*
    Runs callback for each own property of target object.
    Loop can be stopped by returning false from callback.
 */
ep.runForEachProperty = function(target, callback, context){
    for (var key in target) {
        if(!target.hasOwnProperty(key)) continue;

        var value = target[key];
        if(callback.call(context || this, value, key) === false)
            break;
    }
};

//http://stackoverflow.com/questions/2499886/window-onbeforeunload-and-window-location-href-in-ie
ep.common.safelyRedirect = function (redirectUrl, windowObj) {
    if (!windowObj)
        windowObj = window;

    try {
        windowObj.location.href = redirectUrl;
    }
    catch(e){
    }
};

ep.common.OperationUidGenerator = {
    generateUID: function (prefix, url, suffix) {
        return prefix + "_" + url + (suffix ? "_" + suffix : "");
    },

    getPrefix: function (uid, url) {
        var prefixWithIdx = uid.indexOf("_" + url);
        if (prefixWithIdx >= 0) {
            return uid.substr(0, prefixWithIdx);
        }

        return null;
    }
};

ep.common.overrideValidatorSetFocusForTelerikCombobox = function () {
    if (typeof ValidatorSetFocus == 'function') {
        window['_oldValidatorSetFocus'] = ValidatorSetFocus;
        window['ValidatorSetFocus'] = function (val, evt) {
            var ctv = $('#' + val.id).attr('ctv');
            if (ctv)
                val.controltovalidate = ctv;

            window['_oldValidatorSetFocus'](val, evt);
        }
    }
}

ep.common.isScrollHeightSupported = function isScrollHeightSupported() {
    if (isScrollHeightSupported._result === undefined) {
        var $element = $("<div></div>").appendTo("body");
        try {
                
            isScrollHeightSupported._result = $element[0].scrollHeight !== undefined;
                
        } catch (err) {
            isScrollHeightSupported._result = false;
        }
        $element.remove();
    }

    return isScrollHeightSupported._result;
};

ep.common.getActiveElement = function () {
    try {
        return document.activeElement;
    } catch (e) { /* do nothing, document.activeElement can throw error in ie in some circumstances*/ }
};

ep.common.isBodyActive = function() {
    var activeEl = ep.common.getActiveElement();
    //null check for ie, body for chrome and others
    return !activeEl || activeEl === document.body;
};

ep.common.getActiveDialog = function() {
    var activeEl = ep.common.getActiveElement();
    //find wrapper for dialog because focused buttons can be outside of dialog content (element which is actually is dialog widget)
    var $dialog = activeEl && $(activeEl).closest(".ui-dialog");
    $dialog = $dialog || $();
    return $dialog.children(":ui-dialog");
};

ep.common.truncate = function(str, maxLength) {
    if (!str)
        return str;

    return str.substr(0, maxLength);
};

ep.timer = {
    setTimeout: function (action, timeout) {
        return setTimeout(action, timeout);
    },

    clearTimeout: function (timer) {
        clearTimeout(timer)
    },
    
    now: function(){
        return new Date();
    }
};

(function() {
    var doneOnce = function(callback){
        if(!this._doneOnceSubscribed){
            this._doneOnceSubscribed = true;
            return this.done.call(this, callback);
        }

        return this;
    };

    ep.extendPromise = function (promise) {
        promise.doneOnce = doneOnce;
        return promise;
    };

})();

// Delegates

registerNS("EasyProjects.Function");

if (EasyProjects.Function.createDelegate == undefined) {
    // *********************
    // Create delegate
    // *********************
    EasyProjects.Function.createDelegate = function Function$createDelegate(instance, method) {

        return function () {
            return method.apply(instance, arguments);
        };
    };
}

(function(_) {
    if (!_ || _.VERSION !== "1.8.3") {
        return;
    }

    // cancel was added to debounce func, but it hasn't released yet
    // so override 1.8.3 version of debounce -> add cancel to debounced func in 
    // the same was as it was done in latest version (see https://github.com/jashkenas/underscore/blob/master/underscore.js)
    // to be able cancel debounced func right now
    // we need to remove this code after new release of underscore

    var test = _.debounce(function () { }, 10);
    if (test.cancel) {
        throw new Error("debounced.cancel already exists, please remove this code");
    }

    _.debounce = function (func, wait, immediate) {
        var timeout, args, context, timestamp, result;

        var later = function () {
            var last = _.now() - timestamp;

            if (last < wait && last >= 0) {
                timeout = setTimeout(later, wait - last);
            } else {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                    if (!timeout) context = args = null;
                }
            }
        };

        var debounced = function () {
            context = this;
            args = arguments;
            timestamp = _.now();
            var callNow = immediate && !timeout;
            if (!timeout) timeout = setTimeout(later, wait);
            if (callNow) {
                result = func.apply(context, args);
                context = args = null;
            }

            return result;
        };

        debounced.cancel = function () {
            clearTimeout(timeout);
            timeout = null;
        };

        return debounced;
    };

})(window._);

(function ($) {

    jQuery.Event.prototype.epStopPropagation = function () {        
        this.stopPropagation();

        if (!this._isEpPropagationStopped && (this.type === "click" || this.type === "mousedown")) {
            
            let { clientX, clientY, which } = this;
            let newEvent = new jQuery.Event("epclickstopped");
            newEvent.clientX = clientX;
            newEvent.clientY = clientY;
            newEvent.which = which;

            $(this.target).trigger(newEvent, {
                originalType: this.type
            });
        }

        this._isEpPropagationStopped = true;
    };

    jQuery.Event.prototype.epHandled = function(){
        this.originalEvent._epHandled = true;
    };

    jQuery.Event.prototype.isEpHandled = function () {
        return !!this.originalEvent._epHandled;
    };

    /*
    Invokes save/cancel operation whenever user press Enter/Esc key
     */
    $.fn.epAddSaveCancelTriggers = function (options) {
        var saveCallback = options.context ? $.proxy(options.saveCallback, options.context) : options.saveCallback;
        var cancelCallback = options.context ? $.proxy(options.cancelCallback, options.context) : options.cancelCallback;
        var delay = options.delay;
        var events = options.events || "keydown keypress";
        var cancelSelector = options.cancelSelector;

        var self = this;
        this.bind(events, function (e) {
            if ($(this).attr("readonly") == "readonly") {
                return true;
            }

            if (e.keyCode === ep.Keys.enter) {
                if (cancelSelector && $(cancelSelector, self).is($(e.target))) {
                    if (cancelCallback) {
                        cancelCallback();
                    }

                    return false;
                }

                if (saveCallback) {
                    if (delay) {
                        setTimeout(function () { saveCallback(e); }, delay);
                    } else {
                        saveCallback(e);
                    }
                    return false;
                }
            } else if (e.keyCode === ep.Keys.esc) {
                if (cancelCallback) {
                    cancelCallback();
                    return false;
                }
            }
            return true;
        });

        return this;
    };

    $.fn.epAbbreviationFor = function ($source) {
        var $el = this;
        $source.on("keyup.epabbr,change.epabbr", function() {
            $el.val(ep.common.getAbbreviation($(this).val()));
        });

        $el.on("change", function() {
            $source.off(".epabbr");
        });
    };

    $.fn.epAddTabbableInsidePopup = function () {
        var _this = this;

        this.off("keydown.tabbableinsidepopup").on("keydown.tabbableinsidepopup", function (event) {
            // prevent tabbing out of dialogs
            if (event.keyCode !== 9) {
                return;
            }

            var tabbables = _this.find(":tabbable"),
                first = tabbables.filter(":first"),
                last = tabbables.filter(":last");

            if ((event.target === last[0] || event.target === _this) && !event.shiftKey) {
                first.focus();
                event.preventDefault();
            } else if ((event.target === first[0] || event.target === _this) && event.shiftKey) {
                last.focus();
                event.preventDefault();
            }
        });
    };

    $.fn.setWatermark = function(text){
        this.attr('placeholder', text);
        if ($.browser.msie) {
            this.watermark(text, { useNative:false, hideBeforeUnload:false });
        } else {
            this.placeholder();
        }

        return this;
    };

    $.epHeader = function epHeader() {
        return epHeader.result || (epHeader.result = $("#page-header"));
    };

    $.fn.epGetDialogOverlay = function() {
        return this.closest(".ui-dialog")
                   .prev('.ui-widget-overlay');
    };

    (function() {

        const epSelectOnFocusKey = "epSelectOnFocus";
        const updateData = function($el, changes){
            let data = $el.data(epSelectOnFocusKey);
            $el.data(epSelectOnFocusKey, {...data, ...changes});
        };
        
        const setData = function($el, data){
            $el.data(epSelectOnFocusKey, data);
        };
        
        const clearSelectTimeout = function($el){
            let data = $el.data(epSelectOnFocusKey);
            if(data && data.timeout){
                clearTimeout(data.timeout);
            }
        };
        
        $.fn.epSelectOnFocus = function (action) {
            if (!action || action === "on") {
                setData(this, {});
                this.on("focus.selectonfocus", function () {
                    let focusedElement = $(this);
                    clearSelectTimeout(focusedElement);
                    
                    let timeout = setTimeout(function () {
                        let data = focusedElement.data(epSelectOnFocusKey);
                        if (data && data.skipOneTime) {
                            updateData(focusedElement, { skipOneTime: false });
                            return;
                        }
                        if (focusedElement.is(":focus"))
                            focusedElement.select();
                    }, 0); //select all text in any field on focus for easy re-entry. Delay sightly to allow focus to "stick" before selecting.

                    updateData(focusedElement, { timeout: timeout });
                });
            } else if (action === "off") {

                this.off(".selectonfocus");
                
                clearSelectTimeout(this);
                setData(this, null);

            } else if (action === "offOneTime") {

                if (this.data(epSelectOnFocusKey)) {
                    updateData(this, { skipOneTime: true })
                }

            } else {
                throw new Error("unknown action '" + action + "' for epSelectOnFocus");
            }

            return this;
        };
    })();

    $.fn.epFocusNext = function(parentSelector, reverse) {
        var parent = this.closest(parentSelector);
        if (parent.length) {
            var focusable = parent.find(":focusable");
            var current = focusable.index(this);
            if (current !== -1) {
                if (!reverse && current < (focusable.length - 1))
                    focusable.eq(current + 1).focus();
                else if (reverse && current > 0)
                    focusable.eq(current - 1).focus();
            }
        }

        return this;
    };

    $.fn.epFocusFirstInput = function() {
        this.find('input[type=text],textarea')
            .filter(":visible:not([readonly='readonly']):first")
            .focus();

        return this;
    };

    $.fn.epTriggerHandlers = function (eventType) {
        var namespace = _.uniqueId(".eptriggerclickhandlers");

        $(this).on(eventType + namespace, function(e) { e.preventDefault(); }) //disable native handler
            .trigger(eventType)
            .off(eventType + namespace);

        return this;
    };
    
    $.fn.epIsScrollBar = function(){
        if(this.length !== 1)
            return false;
        
        return this[0].tagName === "HTML" || this.data('ep-scrollbar');
    };
    
    $.fn.epScroll = function(options){
        let scrollTop = options.scrollTop;
        let scrollLeft = options.scrollLeft;
        if(options.animate){
            const speed = options.animate.speed || 600;
            let animateProps = {};
            if(scrollTop != null){
                animateProps = { scrollTop };
            }
            
            if(scrollLeft != null){
                animateProps = { ...animateProps, scrollLeft };
            }
            
            this.stop().animate(animateProps, speed, 'swing');
            
        }else{
            if(scrollTop != null) {
                this.scrollTop(scrollTop);
            }
            
            if(scrollLeft != null){
                this.scrollLeft(scrollLeft);
            }
        }
        
        return this;
    };

    function isScrollable(elem, onlyVertical) {
        if (!ep.common.isScrollHeightSupported())
            return false;

        var isHorizontalScrollAllowed = $(elem).css("overflow-x") === "auto" || $(elem).css("overflow-x") === "scroll";
        var isVerticalScrollAllowed = $(elem).css("overflow-y") === "auto" || $(elem).css("overflow-y") === "scroll";

        return (isVerticalScrollAllowed && (elem.clientHeight < elem.scrollHeight)) ||
               (!onlyVertical && isHorizontalScrollAllowed && (elem.clientWidth < elem.scrollWidth));
    }

    $.expr[":"]["ep-scrollable"] = function (elem) {
        return isScrollable(elem, false);
    };

    $.expr[":"]["ep-vscrollable"] = function (elem) {
        return isScrollable(elem, true);
    };

    $.expr[":"]["ep-invisible"] = function(elem) {
        return elem.parentNode === null /* detached */ || $(elem).css("display") === "none" || $(elem).css("visibility") === "hidden";
    };
    
    ep.common.throttleAjaxAction = throttleAjaxAction;
    
    ep.common.GetCopyName = function (allNames, attempedName) {
        var result = attempedName;

        var isNameUnique = !_.find(allNames, function (name) { return name == attempedName; });

        if (!isNameUnique) {
            result = result + ' - ' + ep.common.resources.copyPostfix; // ep.common.resources.copyPostfix should be rendered at the page manually
        }

        return result;
    };


    ep.common.flexNotSupported = function() {
        // display:flex; display:-webkit-flex; display:-moz-flex; display:-o-flex; display:-ms-flex; display:flex'

        // /*&& !/Trident\/|MSIE /.test(window.navigator.userAgent)*/ - checks that browser is ie9-11. 
        //Flex was working with bugs in activity parent path control on taskdetails and was switched off by this line
        // bugs were fixed by proper css, but if they be reproduced again this checking should be added to if to switch off flex support for IE9-11

        //(('flexWrap' in d) || ('WebkitFlexWrap' in d) || ('msFlexWrap' in d)) - checks, that flex feature is supported by browser
        var d = document.documentElement.style;
        if ((('flexWrap' in d) || ('WebkitFlexWrap' in d) || ('msFlexWrap' in d)) ) {
            return false;
        }

        return true;
    }();
    //IE startsWith fix
    (function ieStartWith() {
        if (!String.prototype.startsWith) {
            String.prototype.startsWith = function (searchString, position) {
                position = position || 0;
                return this.indexOf(searchString, position) === position;
            };
        }
    })();
    // REGEXP PATTERN for link parsing inside string
    var LINKPATTERN = /((https?:\/\/[a-z0-9]+)|(www\.[a-z0-9]+\.\S)|(ftp:\/\/))[a-z0-9//;:\?\._~&#\-=+%\)\(]*/ig;

    ep.common.linkifyTextString = function (string, target) {
        string = _.escape(string) // string tags cleanup
        target = target ? " target='" + target + "'" : "";
        string = string.replace(LINKPATTERN, function (match, p1) {
            var url = match;
            if (p1.startsWith("www")) {
                url = "http://" + url;
            }
            return "<a href='" + url + "'" + target + ">" + match + "</a>";
            
        });
        return string;
    };

    ep.common.maxCountOfItemsInTooltip = 20;

    ep.common.getItemsSetForLimitedTooltip = function (items) {
        var tooltipLimiter = ep.common.maxCountOfItemsInTooltip,
            limitOverflowed = items.length > tooltipLimiter ? true : false;
        if (limitOverflowed) {
            items = items.slice(0, tooltipLimiter);
        }
        return { 'items': items, 'limitOverflowed': limitOverflowed };
    };

    ep.common.addBodyClassOnDetailsOpen = function (classname) {
        var className = classname || 'opened-details';

        $.subscribe(ep.details.events.visibilityChanged, function (e, data) {
            $('body').toggleClass(className, data.detailsVisible);
        });
    };
    ep.common.isPrintableKey = isPrintableKey;

    ep.common.isNumberKey = isNumberKey;

    String.prototype.epLeftPad = function(char, length){
        if(this.length >= length){
            return this;
        }
        
        var n = length - this.length;
        var pad = "";
        for(var i = 0; i < n; i++){
            pad += char;
        }
        
        return pad + this;
    };

    ep.common.setCorner = function (className, round) {
        $(className).corner("round " + round + "px");
    };
})(jQuery);

/* eslint-enable */

