/* eslint-disable */

import {RequestAntiForgeryHelper, verificationTokenKey} from "./RequestAntiForgeryHelper";
import {ServerVersionHelper} from "./ServerVersionHelper";
let epCommon;

window.ep = window.ep || {};
(function (ep) {

    var postingDataPostProcessors = [];

    var postProcessPostingData = function(data){
        if(!postingDataPostProcessors.length)
            return data;

        data = data || {};
        if(typeof data !== 'object')
            return data;

        for (var i = 0; i < postingDataPostProcessors.length; i++) {
            postingDataPostProcessors[i] (data);
        }

        return data;
    };

    var responsePreProcessors = [];

    var preProcessResponse = function(response, request, url){
        if(!responsePreProcessors.length)
            return;

        if(!response || typeof response !== 'object')
            return;

        var e = {
            response: response,
            url: url,
            request: request
        };

        for (var i = 0; i < responsePreProcessors.length; i++) {
            responsePreProcessors[i](e);
        }
    };

    ep.common = ep.common || {};

    var objectNotFoundEvent = null;

    /**
     * @returns {ep.common.EventWrapper}
     */
    var getObjectNotFoundEventInstance = function(){
        if(!objectNotFoundEvent){
            objectNotFoundEvent = new ep.common.EventWrapper("ajax/objectnotfoundevent");
        }
        return objectNotFoundEvent;
    };

    ep.common.publishObjectNotFound = function({ entityName, objectKey, eventSource = null }){
        if(_.isString(objectKey)){
            objectKey = parseInt(objectKey);
        }
        getObjectNotFoundEventInstance().notify({ 
            entityName, 
            objectKey, 
            eventSource,
            isTask: entityName === "Task",
            isProject: entityName === "Project"
        });
    };

    /**
     * @returns {ep.common.EventWrapper}
     */
    ep.common.objectNotFoundEvent = function(){
        return getObjectNotFoundEventInstance().getPublicInterface();
    };

    /* Error handling  */

    ep.common.buildAjaxErrorHandler = function(customHandlers) {
        return function (request) {
            if (request.statusText == "abort") {
                return;
            }


            var responseText = request.responseText || "{}";
            var response = {};
            try {
                response = JSON.parse(responseText);
            }catch(e) {
                //ignore errors because sometimes response might be html (for example on unauthorized response)
            }
            
            if (this.ignoreErrors || (this.ignoreErrorsList && _.some(this.ignoreErrorsList, e => response.ErrorType===e ))) {
                return;
            }

            //reloads current page if request was for not authorized user (as result server redirects to login page)
            if (request.status === 401) {
                location.reload();
                return;
            }

            //change response to empty object in response empty because JSON.parse fails on empty string (in ie8 and maybe in other browsers too)

            var defaultHandler = function () {
                if (response.ErrorPageUrl != undefined) {
                    $(window).unbind();
                    window.location = response.ErrorPageUrl;
                }
            };

            var isHandled = false;
            if (customHandlers && customHandlers.length) {
                var ctx = this;
                isHandled = _.some(customHandlers, function (customHandler) {
                    return customHandler.call(ctx, response);
                });
            }

            if (!isHandled) {
                if (response.ErrorType === "ObjectNotFound") {

                    var objectInfo = {
                        entityName: response.EntityName,
                        objectKey: response.ObjectKey,
                        dialogOnCloseCallback: this.errorDialogOnCloseCallback
                    };
                    var needToNotify = ep.errorManager.handleObjectNotFoundError(objectInfo);
                    if (needToNotify)
                        ep.common.publishObjectNotFound(objectInfo);
                } else if (response.ErrorType === "UserFriendlyError") {
                    ep.errorManager.handleUserFriendlyError(response.Message, this.errorDialogOnCloseCallback);
                } else if (response.ErrorType === "UpgradePlanRequired") {
                    ep.upgradePlanHelper.showWindowForFeature(response.Feature);
                } else if (response.ErrorType === "ServerVersionException"){
                    ep.errorManager.handleServerVersionError();
                }
                else {
                    defaultHandler();
                }
            }
        }
    };

    ep.common.onAjaxError = ep.common.buildAjaxErrorHandler(null);

    // overriding Date.toJSON to serialize local time (original implementation serializes UTC time)
    // we could use replacer parameter of JSON.stringify but unfortunately it calls toJSON first
    Date.prototype.toJSON = function () {
        if (!isFinite(this.valueOf())) {
            return null;
        }

        // trimming the time part away because dateParser/date select returns dates with time
        return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())).toISOString();
    };

    Date.prototype.toJSONWithTime = function () {
        if (!isFinite(this.valueOf())) {
            return null;
        }

        return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(), this.getMilliseconds())).toISOString();
    };

    ep.common.jsonStringify = function (data) {
        return JSON.stringify(data);
    };

    ep.common.isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d*))?Z?$/;
    ep.common.jsonParse = function (json) {
        if (!json) {
            return null;
        }

        function reviver(key, value) {
            if (typeof value === 'string') {
                var date = Date.fromISOString(value);
                if (date) {
                    // treating parsed date as local client date
                    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
                }
            }
            return value;
        }

        return JSON.parse(json, reviver);
    };

    ep.common.addAjaxPostingDataPostProcessor = function(postProcessor){
        postingDataPostProcessors.push(postProcessor);
    };

    ep.common.addResponsePreProcessor = function(preProcessor){
        responsePreProcessors.push(preProcessor);
    };

    if(ep.testsEnv){
        ep.common.preProcessReponse = preProcessResponse;

        ep.common.clearResponsePreProcessors = function(){
            responsePreProcessors = [];
        };
    }

    ep.common.get = function (url, data, callback, optionalParams) {
        // shift arguments if data argument was omitted
        if (jQuery.isFunction(data)) {
            optionalParams = optionalParams || callback;
            callback = data;
            data = undefined;
        }

        optionalParams = optionalParams || {};
        return ep.common.ajax({
            url: url,
            type: "get",
            dataType: optionalParams.type,
            data: data,
            success: callback,
            skipPostProcess: optionalParams.skipPostProcess
        });
    };

    ep.common.post = function (url, data, callback, type, onError) {
        // shift arguments if data argument was omitted
        if (jQuery.isFunction(data)) {
            type = type || callback;
            callback = data;
            data = undefined;
        }

        return ep.common.ajax({
            url: url,
            type: "post",
            dataType: type,
            data: data,
            success: callback,
            error: onError
        });
    };

    ep.common.ajax = function (options) {
        if (options.error === undefined) {
            options.error = ep.common.onAjaxError;
        }

        if (!options.skipPostProcess){
            options.data = postProcessPostingData(options.data);
        }
        options.headers = { "X-TimeZoneOffset": -(new Date().getTimezoneOffset()) + '' }; // todo: post time zone offset only if changed

        return jQuery.ajax(options)
                     .done(function(response){
                        preProcessResponse(response, options.data, options.url);
                     });
    };

    var lazyPromises = {};

    ep.common.repostJson = function (url, data, callback, optionalParams) {
        if (!url) {
            throw new Error('url argument is required');
        };

        if (lazyPromises[url])
            lazyPromises[url].abort();

        var promise = ep.common.postJson(url, data, callback, optionalParams)
                               .always(function () {
                                   lazyPromises[url] = null;
                               });

        lazyPromises[url] = promise;

        return promise;
    }

    var setOptionalParams = function (options, optionalParams) {
        if (optionalParams.needIgnoreAccountActivity) {
            _.extend(options.headers, { "NeedIgnoreAccountActivity": true });
        }

        if (optionalParams.headers) {
            Object.assign(options.headers, optionalParams.headers)
        }

        if (optionalParams.errorDialogOnClose) {
            _.extend(options, { errorDialogOnCloseCallback: optionalParams.errorDialogOnClose });
        }

        if (optionalParams.ignoreErrors) {
            _.extend(options, { ignoreErrors: optionalParams.ignoreErrors });
        }

        if (optionalParams.ignoreErrorsList) {
            Object.assign(options, { ignoreErrorsList: optionalParams.ignoreErrorsList });
        }
    };

    ep.common.postJson = function (url, data, callback, optionalParams) {
        optionalParams = optionalParams || {};
        // shift arguments if data argument was omitted
        if (jQuery.isFunction(data)) {
            callback = data;
            data = undefined;
        }

        data = postProcessPostingData(data);

        var customErrorHandlers = [];
        var errorHandler = ep.common.buildAjaxErrorHandler(customErrorHandlers);

        var options = {
            url: url,
            type: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: (data && typeof data !== 'string') ? ep.common.jsonStringify(data) : data, // serializing data with own serializer
            dataType: 'json',
            converters: { "text json": ep.common.jsonParse }, // overriding standard jQuery json parser
            success: callback,
            error: errorHandler,
            headers: {
                "X-TimeZoneOffset": -(new Date().getTimezoneOffset()) + '' // todo: post time zone offset only if changed
            },
            optionalParams: optionalParams
        };

        setOptionalParams(options, optionalParams);

        var xhr = jQuery.ajax(options)
                        .done(function(response){
                           preProcessResponse(response, data, url);
                        });

        xhr.addErrorHandler = function(callback){
            customErrorHandlers.unshift(callback);
            return this;
        };

        return xhr;
    };

    (function(){        
        $.ajaxPrefilter(function (options, originalOptions, jqXHR){
            RequestAntiForgeryHelper.setAntiForgeryToken(options);
            ServerVersionHelper.setServerVersion(options);
        });

        let origSubmit  =  HTMLFormElement.prototype.submit;
        HTMLFormElement.prototype.submit = function () {
            let tokenField = $(`input[name=${verificationTokenKey}]`, this);
            if(!tokenField.length) {
                $(this).append(RequestAntiForgeryHelper.getAntiForgeryField());
            } else {
                tokenField.val(RequestAntiForgeryHelper.getAntiForgeryToken());
            }
            
            return origSubmit.apply(this, arguments);
        };
    })();

    epCommon = ep.common;
})(ep);

export  {epCommon};

/* eslint-enable */