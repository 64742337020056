/*global navigator:true,Image:true*/
(function (ep, window) {
    "use strict";

    var TraceKit = window.TraceKit || null;

    var EpLogger = function(){
        this.init = function(errorsLogUrl){
            if (this._isInitialized || !TraceKit) {
                return;
            }

            this._errorsLogUrl = errorsLogUrl;
            this._isInitialized = true;
            this._traceKit = TraceKit.noConflict();
            this._traceKit.report.subscribe($.proxy(this._processError, this));

            this.logOnlyMessage = false;
        };

        this.logError = function(error){
            try {
                this._traceKit.report(error);
            }
            catch (traceKitException) {
                if (error !== traceKitException) {
                    throw traceKitException;
                }
            }
        };

        this._processError = function(stackTrace){
            if (this.logOnlyMessage){
                this._sendToServer(stackTrace.message);
                this.logOnlyMessage = false;
                return;
            }

            var stack = [],
                qs = {};

            if (stackTrace.stack && stackTrace.stack.length) {
                $.each(stackTrace.stack, function (i, frame) {
                    stack.push({
                        'LineNumber': frame.line,
                        'ClassName': 'line ' + frame.line + ', column ' + frame.column,
                        'FileName': frame.url,
                        'MethodName': frame.func || '[anonymous]'
                    });
                });
            }

            if (window.location.search && window.location.search.length > 1) {
                $.each(window.location.search.substring(1).split('&'), function (i, segment) {
                    var parts = segment.split('=');
                    if (parts && parts.length == 2) {
                        qs[decodeURIComponent(parts[0])] = parts[1];
                    }
                });
            }

            this._sendToServer({
                'OccurredOn': new Date(), // TODO .toJSONWithTime(),
                'Details': {
                    'Error': {
                        'ClassName': stackTrace.name,
                        'Message': stackTrace.message || 'Script error',
                        'StackTrace': stack
                    },
                    'Environment': {
                        'UserLanguage': (navigator || {}).userLanguage,
                        'DocumentMode': document.documentMode,
                        'Browser': (navigator || {}).appCodeName,
                        'BrowserName': (navigator || {}).appName,
                        'BrowserVersion': (navigator || {}).appVersion,
                        'Platform': (navigator || {}).platform
                    },
                    'Request': {
                        'Url': document.location.href,
                        'QueryString': qs,
                        'Headers': {
                            'UserAgent': (navigator || {}).userAgent,
                            'Referer': document.referrer,
                            'Host': document.domain
                        }
                    }
                }
            });
        };

        this._sendToServer = function(data){
            if(this._errorsLogUrl){
                var img = new Image(1,1);
                img.src = this._errorsLogUrl + '?errorInfo=' + encodeURIComponent(JSON.stringify(data, null, "\t"));
            }
        };
    };

    ep.EpLogger = new EpLogger();
})(ep, window);
