export class ServerVersionHelper {
    static get serverVersionKey() { return "serverVersion";}
    
    static getVersion() {
        return (ep.settings || {})[ServerVersionHelper.serverVersionKey] || "";
    }

    static setServerVersion(requestOptions) {
        requestOptions.headers = requestOptions.headers || {};
        requestOptions.headers[ServerVersionHelper.serverVersionKey] = ServerVersionHelper.getVersion();
    }
}